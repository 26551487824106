import Banner from "../components/Banner"
import  Stats from "../components/Stats"


const Home = () => {

    return(

        <>
            <Banner />
            <Stats />
            
        </>
    )


}



export default Home










